import React, { useState } from 'react';

import WelcomeRocks from '../components/index/rocks';
import Menu from '../components/menu/Menu';



//import '../../node_modules/bootstrap/dist/css/bootstrap.css'; // Archivo CSS de Bootstrap 4 
//import '../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 

/*
global.jQuery = require('jquery');
require('bootstrap');
*/

const rocks = ()  => {

  return ( 
      <>  
		<Menu bgcolor='navbar navbar-expand-md navbar-dark fixed-top bg-purple'/>
		<WelcomeRocks/>
      </>
  )
} 

export default rocks;