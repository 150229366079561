import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { fadeIn, slideUp } from './indexVariants';
import * as welcomeStyles from './welcomerocks.module.scss';


 const Rocks= () =>  {
  const [ref, isVisible] = useInView({ threshold: 0.6 });
  const controls = useAnimation();
// styles


  useEffect(() => {
    if(isVisible)
      controls.start('animate');
  }, [isVisible, controls])

  return (
    
    <section ref={ref}  className={welcomeStyles.backgroundrocks}>
      <h1 className={welcomeStyles.h1}>
        30 rock/snl
      </h1>
     
    <section ref={ref}  className={welcomeStyles.effectcolor}>
    <section ref={ref}  className={welcomeStyles.container}>
    <div className={welcomeStyles.content}>
       <div class="row">
         <div class="col-md-5 col-sx-12"></div>
         <div class="col-md-7 col-sx-12">
            
         </div>
       </div>
      </div>
    </section>
    </section>
    
    <section className={welcomeStyles.top}>
    <div class="container">
      <div class="row">
          <div class="col-xs-6 col-xs-offset-3">
          <p className={welcomeStyles.p}>It’s hard to believe that 30 Rock is really over. This has been the most fun I’ve ever had as an actor. Although there are countless clips that could be included on this site, here are a few I think you will truly enjoy. Thank you to Tina, Robert Carlock, Lorne Michaels and everyone at 30 Rock.</p>
              <p className={welcomeStyles.p}>– Alec</p>
          </div>
      </div>
      <div class="row">
        <div class="col-xs-6 col-xs-offset-3 justify-content-center row">
         <iframe title="vimeo-player" src="https://player.vimeo.com/video/55315710" width="640" height="360" frameborder="0" allowfullscreen>
         </iframe>
         <p className={welcomeStyles.width640}>30 Rock Clip Reel"</p>
        </div>
      </div>
    </div>

</section>
      
    
</section>
    
    /*
<div class="container-fluid" className={welcomeStyles.effectcolor}>
<div class="container" >
<section ref={ref}  >
    <div class="row">
        <div class="col-12">
            <section ref={ref}  className={welcomeStyles.backgroundrocks}>
            <section   className={welcomeStyles.effectcolor}>
            </section>
            </section>
        </div>
    </div>
    <div class="row">
    <motion.h1
        custom={1}
        variants={fadeIn}
        initial='initial'
        animate={controls}
        >
        30 Rocks/snl
      </motion.h1>
	  
       <div className={welcomeStyles.content}>
         <div class="row">
           <div class="col-sx-12">
           
              <p className={welcomeStyles.p}>It’s hard to believe that 30 Rock is really over. This has been the most fun I’ve ever had as an actor. Although there are countless clips that could be included on this site, here are a few I think you will truly enjoy. Thank you to Tina, Robert Carlock, Lorne Michaels and everyone at 30 Rock.</p>
              <p className={welcomeStyles.p}>– Alec</p>
            </div>
         </div>
      </div>
    </div>
</section>
</div>
</div>
  */
	 
  )
}

export default Rocks;
