// extracted by mini-css-extract-plugin
export var container = "welcomerocks-module--container--1Y6lm";
export var content = "welcomerocks-module--content--cwnSq";
export var sideBar = "welcomerocks-module--side-bar--35sI_";
export var p = "welcomerocks-module--p--1fCHA";
export var logoimage = "welcomerocks-module--logoimage---Gu0O";
export var navItem = "welcomerocks-module--nav-item--5QuIt";
export var centrada = "welcomerocks-module--centrada--1j49j";
export var backgroundrocks = "welcomerocks-module--backgroundrocks--3EegK";
export var h1 = "welcomerocks-module--h1--12jTt";
export var top = "welcomerocks-module--top--2zx5o";
export var effectcolor = "welcomerocks-module--effectcolor--3GeDt";
export var width640 = "welcomerocks-module--width640--1mJC5";